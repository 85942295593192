import React from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import HeroNarc from "@components/heroNarc"
import Section from "@components/section"
import Container from "@components/container"
import Anchor from "@components/anchor"
import BlurredBgCallout from "@components/blurredBgCallout"
import MediaAndText from "@components/mediaAndText"
import References from "@components/references"
import { Heading1, purpleButton } from "@styles/ui"

// Assets
import numberOne from "@images/icons/icon-number-one.svg"
import numberTwo from "@images/icons/icon-number-two.svg"

const TransitioningFromXyrem = ({ location, data }) => {
  const heroImg = withArtDirection(getImage(data.heroDesktop), [
    {
      media: "(max-width: 767px)",
      image: getImage(data.heroMobile),
    },
  ])

  const dosingLink = "/narcolepsy/dosing-and-titration/"
  const resourcesLink = "/narcolepsy/prescriber-resources/"
  const linkUrl =
    "/narcolepsy/prescriber-resources/?activeVideoIndex=2#patient-stories"

  const linkText = <>Hear Oscar&apos;s story</>

  const mediaData = {
    firstVideo: {
      eyebrowText: "Watch video",
      text: "Listen to experts discuss transitioning patients from XYREM to XYWAV treatments",
      image: data.videoThumb1,
      videoId: "6pmpy3s9ai",
    },
    secondVideo: {
      eyebrowText: "Watch video",
      text: "Transitioning your appropriate XYREM patients to XYWAV",
      image: data.videoThumb2,
      videoId: "m7dn36wv0e",
    },
  }

  const referenceData = [
    <>
      XYWAV<sup>&reg;</sup> (calcium, magnesium, potassium, and sodium
      oxybates). Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals,
      Inc.
    </>,
    <>Data on File (JZP258-2020-016). Jazz Pharmaceuticals, Inc.</>,
    <>
      XYREM<sup>&reg;</sup> (sodium oxybate). Prescribing Information. Palo
      Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
    <>
      Lawless HT, Rapacki F, Horne J, Hayes A. The taste of calcium and
      magnesium salts and anionic modifications.{" "}
      <span tw="italic">Food Qual Pref</span>. 2003;14:319-325.
    </>,
  ]

  return (
    <Layout location={location}>
      <Seo
        title="How To Transition Patients from XYREM to XYWAV® | XYWAV HCP"
        description="Learn about successfully transitioning your patients to XYWAV® (calcium, magnesium, potassium, and sodium oxybates) oral. See XYWAV oral solution full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <HeroNarc
        eyebrowText="Transitioning from XYREM to XYWAV"
        bgColour="blue"
        imgSrc={heroImg}
        altText="Doctor and patient consulting about XYWAV."
        mobileHeight="65"
      >
        <h1 css={[Heading1, tw`pt-4 md:(mr-[100px]) xl:(mr-0) max-w-[570px]`]}>
          How to <strong>transition</strong> your appropriate XYREM
          <sup tw="tracking-[0.6px]">&reg;</sup> (sodium&nbsp;oxybate) oral
          solution <strong>patients to XYWAV</strong>
        </h1>
      </HeroNarc>

      <Section addedStyles={tw`pt-0`}>
        <Container
          addedStyles={tw`flex flex-col pt-10 md:(flex-row) md:(pt-10 min-h-[220px]) xl:pt-0`}
        >
          <div tw="gradient42 h-[197px] w-[calc(100% + 17.5px)] pt-[68px] pb-12 pl-[54px] pr-6 rounded-[0 0 100px 0] translate-x-[-17.5px] md:(w-1/2 h-[220px] ml-[-18px] translate-x-0 mr-[20px]) xl:(w-[calc(50% + 105px)] absolute left-[-135px])">
            <p tw="text-2xl relative before:(w-[70px] h-[6px] absolute gradient1 top-[-20px] left-0) xl:(text-4xl w-[49%] translate-x-[82px])">
              Initiate at the{" "}
              <strong>
                same dose (gram for gram) and regimen as XYREM<sup>1</sup>
              </strong>
            </p>
          </div>
          <div tw="pt-10 md:(w-1/2 min-w-[50%] ml-[15px]) xl:(ml-[585px] pl-[30px])">
            <div tw="flex">
              <div tw="min-w-[50px]">
                <img src={numberOne} />
              </div>
              <p tw="pl-5 pt-2.5 xl:max-w-[425px]">
                On the first night of dosing with XYWAV, initiate treatment at
                the same dose—gram for gram—and regimen as XYREM
                <sup>
                  <strong> 1</strong>
                </sup>
              </p>
            </div>
            <div tw="flex pt-6">
              <div tw="min-w-[50px]">
                <img src={numberTwo} />
              </div>
              <p tw="pl-5 pt-2.5">
                Titrate as needed based on efficacy and tolerability
                <sup>
                  <strong> 1</strong>
                </sup>
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section bgColour="gradient42">
        <Container>
          <p tw="text-2xl pr-[1px] pt-1 md:text-4xl">
            69% of patients transitioning from XYREM to XYWAV{" "}
            <strong>
              had no change in dosage<sup>1,2</sup>
            </strong>
          </p>
          <p tw="pt-6">
            In the clinical trial with XYWAV in adult patients with narcolepsy,
            59 patients who were taking XYREM at study entry were switched from
            XYREM to XYWAV. The figure below indicates the outcome related to
            changes in XYWAV dosage from study entry to the stable-dose period
            in patients who switched from XYREM to XYWAV.
            <sup tw="text-[10px] font-semibold">1,2</sup>
          </p>
          <p tw="uppercase font-semibold pt-[30px]">
            Dose changes during transition from Xyrem to Xywav in the clinical
            trial (<span tw="lowercase">n</span>=59)<sup>1,2</sup>
          </p>
          <div tw="pt-5">
            <picture>
              <source
                srcSet="/images/Transitioning-patients-dosage-chart.svg"
                media="(min-width: 768px)"
              />
              <img
                src="/images/Transitioning-patients-dosage-chart-mobile.svg"
                alt="69% of patients transitioning from XYREM to XYWAV had no change in dosage chart."
              />
            </picture>
          </div>
          <ul tw="pl-4 mt-[30px] relative">
            <li tw="before:(content['&bull;'] absolute left-0 top-0)">
              Dosage changes of ≤1.5 g/night were considered within 1 titration
              step in the clinical trial<sup>1</sup>
            </li>
          </ul>
          <Anchor
            link={resourcesLink}
            addedStyles={[
              purpleButton,
              tw`relative z-10 max-w-[340px] my-[22px] mx-auto py-2.5 px-11 md:(max-w-[458px] mt-10 px-0 h-[50px])`,
            ]}
          >
            xywav dosing &amp; Titration&nbsp;guide
          </Anchor>
        </Container>
      </Section>

      <Section>
        <Container>
          <BlurredBgCallout linkUrl={linkUrl} linkText={linkText}>
            <blockquote tw="font-semibold text-2xl md:(text-4xl mb-[50px])">
              “When I transitioned from XYREM to XYWAV, my doctor told me to
              just start on the dose I was on...I basically just picked up where
              I left off.”
              <span tw="text-xl font-normal inline-block pt-4 md:text-4xl">
                – Oscar, XYWAV patient with narcolepsy type 1
              </span>
            </blockquote>
            <Anchor
              link={linkUrl}
              addedStyles={[
                purpleButton,
                tw`hidden md:(block mx-auto max-w-[351px] min-h-[50px] mt-0 flex items-center px-0)`,
              ]}
            >
              Hear Oscar&apos;s story
            </Anchor>
          </BlurredBgCallout>
        </Container>
        <Container>
          <div tw="flex flex-col mt-[60px] md:(flex-row mt-[55px])">
            <picture tw="relative md:(w-1/2 left-[-18px]) xl:(min-w-[705px] min-h-[447px] left-[-55px]) 2xl:left-[-135px]">
              <source
                srcSet="/images/xywav-bottle.png"
                media="(min-width: 768px)"
              />
              <img
                src="/images/xywav-bottle-mobile.png"
                alt="When transitioning patients inform them of the taste difference between XYREM and XYWAV."
                tw="w-full"
              />
              <div tw="absolute w-[60%] h-full top-0 right-0 text-2xl  flex items-center md:w-1/2">
                <p tw="relative text-2xl pr-4 md:text-xl xl:text-4xl 2xl:pr-[80px]">
                  Because XYWAV contains other cations, inform patients that{" "}
                  <strong>
                    it will taste different than XYREM.<sup>1,3,4</sup>
                  </strong>
                </p>
              </div>
            </picture>
            <div tw="mt-20 md:(w-1/2 flex justify-end items-center  mt-0)">
              <p tw="text-2xl relative before:(absolute w-[70px] h-[6px] gradient44 top-[-20px] left-0) xl:(text-4xl)">
                Remind your patients that while XYWAV is supplied in a 6-oz
                bottle and XYREM is supplied in an 8-oz bottle,{" "}
                <strong>
                  both bottles contain the same amount of medication
                  <sup>1,3</sup>
                </strong>
              </p>
            </div>
          </div>
        </Container>
      </Section>

      <Section bgColour="gradient9">
        <div id="video" />
        <MediaAndText
          anchor="xywavkol"
          eyebrowText={mediaData.firstVideo.eyebrowText}
          text={mediaData.firstVideo.text}
          videoImage={mediaData.firstVideo.image}
          videoId={mediaData.firstVideo.videoId}
          altText="Watch the video to learn more about transitioning patients from XYREM to XYWAV treatments."
        />
        <MediaAndText
          eyebrowText={mediaData.secondVideo.eyebrowText}
          text={mediaData.secondVideo.text}
          videoImage={mediaData.secondVideo.image}
          videoId={mediaData.secondVideo.videoId}
          altText="Watch the video to learn more about transitioning your appropriate XYREM patients to XYWAV."
        />
        <Container>
          <Anchor
            link={dosingLink}
            addedStyles={[
              purpleButton,
              tw`relative z-10 max-w-[342px] mb-4 mx-auto h-[60px] md:(max-w-[444px] px-0) xl:h-[50px]`,
            ]}
          >
            XYWAV Dosing &amp; Titration&nbsp;
          </Anchor>
        </Container>
      </Section>

      <References referenceData={referenceData} paddingPrimary />
    </Layout>
  )
}

export default TransitioningFromXyrem

export const pageQuery = graphql`
  query TransitioningQuery {
    heroMobile: file(
      relativePath: {
        eq: "transitioning-from-xyrem/Doctor-transitioning-patient-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroDesktop: file(
      relativePath: {
        eq: "transitioning-from-xyrem/Doctor-transitioning-patient.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoThumb1: file(
      relativePath: {
        eq: "transitioning-from-xyrem/transitioning-patients-video-screenshot.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoThumb2: file(
      relativePath: {
        eq: "transitioning-from-xyrem/transitioning-appropriate-xyrem-patients-video-screenshot.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
