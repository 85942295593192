import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

// Components
import Container from "@components/container"
import Section from "@components/section"

const References = ({ referenceData, paddingPrimary }) => {
  const heading = referenceData.length > 1 ? "References:" : "Reference:"

  return (
    <Section
      type={paddingPrimary ? "primary" : "none"}
      addedStyles={tw`pb-0 xl:(pb-0)`}
    >
      <Container>
        <h2 tw="font-semibold mb-2.5 text-[12px]">{heading}</h2>
        <ol tw="list-decimal padding-inline-start[15px]">
          {referenceData &&
            referenceData.map((item, i) => (
              <li key={i} tw="mb-1.5 text-[12px]">
                {item}
              </li>
            ))}
        </ol>
      </Container>
    </Section>
  )
}

export default References

References.propTypes = {
  referenceData: PropTypes.array.isRequired,
  paddingPrimary: PropTypes.bool,
}
References.defaultProp = {
  paddingPrimary: true,
}
